/*
* BASE DEPENDENCIES
*/
/*****************************************
* ECCO - FUNCTIONS
*****************************************/
/*****************************************
* ECCO - MIXINS
*****************************************/
/*
* SHARED
*/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*==============================
	INPUT, TEXTAREA, SELECT
==============================*/
.input, .textarea {
  transition: border-color .11s ease, box-shadow .21s ease;
  box-shadow: none;
  width: 100%;
  font-size: 1em;
  border-radius: 0;
  border-color: #002c45;
  border-style: solid;
  border-width: 2px;
  background-color: #ffffff;
  color: #2a2a2a;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.input:focus, .textarea:focus {
  border-color: #ffd700;
  box-shadow: 0 8px 26px rgba(0, 0, 0, 0.12);
  outline: 0;
}

.input[disabled], .textarea[disabled] {
  border-color: #e3e3e3;
  background-color: #ebebeb;
  color: #444444;
  cursor: not-allowed;
}

/*==============================
	CHECKBOX, SWITCH, RADIO
==============================*/
/*
* LAYOUT
*/
.button {
  border-radius: 0;
  padding: 5px 2em;
  margin: 0;
  background-color: #ffd700;
  color: #383433;
  text-align: center;
  transition: background-color .15s ease, box-shadow .15s ease;
  position: relative;
}

.button:not([disabled]):hover, .button:not([disabled]):focus, .button:not([disabled]):active {
  z-index: 3;
  outline: 0;
}

.button[disabled] {
  color: #999999;
  background-color: #ebebeb;
  cursor: not-allowed;
}

.input {
  min-height: 52px;
  padding: .3em 1em;
}

.input.-main {
  min-height: 64px;
  font-size: 1.15em;
}

.textarea {
  padding: .5em 1em;
  resize: vertical;
  height: auto;
  min-height: 6.5em;
}

.form {
  padding: 0;
  margin: 0 auto 2em;
  /* &__fieldset {
		grid-column: span 2;
		padding: .85em 1.5em .625em;
		border: 1px $form-item-border-color solid;
		border-radius: $form-item-border-radius;
	}

	&__legend {
		padding: 0 .5em;
		font-size: 1.35rem;
		font-weight: bold;
	} */
}

.form__fields {
  display: grid;
  grid-gap: 1.5rem;
  max-width: 80rem;
}

@media screen and (min-width: 45rem) {
  .form__fields {
    grid-template-columns: auto auto;
  }
}

.form__section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media screen and (min-width: 40rem) {
  .form__section.-full {
    grid-column: span 2;
  }
}

.form__section.-to-center {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.form__section.-to-right {
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
}

.form__button {
  min-height: 52px;
}

.form__button:nth-last-child(n+2), .form__button:nth-last-child(n+2) ~ .form__button {
  margin-bottom: .5em;
}

.form__button:not(:first-child):not(.-block) {
  margin-left: .5em;
}

.form__label {
  display: block;
  margin-bottom: .4em;
  cursor: default;
}
