
.form {
	padding: 0;
	margin: 0 auto 2em;

	&__fields {
		display: grid;
		grid-gap: 1.5rem;
		max-width: 80rem;

		@include min(45rem) {
			grid-template-columns: auto auto;
		}
	}

	&__section {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		&.-full {
			@include min(40rem) {
				grid-column: span 2;
			}
		}

		&.-to-center {
			align-items: center;
			justify-content: center;
			text-align: center;
		}

		&.-to-right {
			align-items: flex-end;
			justify-content: flex-end;
			text-align: right;
		}

	}

	&__button {
		min-height: $form-item-standard-size;

		@include quantity-query(min 2) {
			margin-bottom: .5em;
		}

		&:not(:first-child):not(.-block) {
			margin-left: .5em;
		}
	}

	&__label {
		display: block;
		margin-bottom: .4em;
		cursor: default;
	}

	/* &__fieldset {
		grid-column: span 2;
		padding: .85em 1.5em .625em;
		border: 1px $form-item-border-color solid;
		border-radius: $form-item-border-radius;
	}

	&__legend {
		padding: 0 .5em;
		font-size: 1.35rem;
		font-weight: bold;
	} */
}
