
.button {
	border-radius: $button-radius;
	padding: 5px 2em;
	margin: 0;
	background-color: $button-bg;
	color: $button-fg;
	text-align: center;
	transition: background-color .15s ease, box-shadow .15s ease;
	position: relative;

	&:not([disabled]) {
		&:hover,
		&:focus,
		&:active {
			z-index: 3;
			outline: 0;
			//box-shadow: 0 5px 20px $button-bg;
		}
	}

	&[disabled] {
		color: #999999;
		background-color: #ebebeb;
		cursor: not-allowed;
	}
}
