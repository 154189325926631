
/*==============================
	FONTS
==============================*/

$font-monospace: SFMono-Regular, 'Roboto Mono', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
$font-body: Roboto, Nunito;

/*==============================
	COLORS
==============================*/

$color-base: #242424;
$color-brand: #002c45;
$color-secondary: #ffd700;
$color-tertiary: #f1f5f7;
$color-light: #f6f2ef;
$color-dark: #383433;


/*==============================
	SIZES
==============================*/

$max-width: 1600px !global;
$grid-spacing: 2vmax;
$grid-spacing-fixed: 30px;


/* COMPONENT VARIABLES */

/*==============================
	FORMS
==============================*/

$form-active-color: $color-secondary;
$form-active-text: #ffffff;

$form-item-standard-size: 52px;
$form-item-xl-size: 64px;
$form-item-border-color: $color-brand;
$form-item-border-width: 2px;
$form-item-border-radius: 0;
$form-item-bg: #ffffff;
$form-item-fg: #2a2a2a;
$form-disabled-item-border-color: #e3e3e3;
$form-disabled-item-bg: #ebebeb;
$form-disabled-item-fg: lighten($form-item-fg, 10%);
$form-item-focus-shadow: rgba(0, 0, 0, .12);
$form-icon-color: #cccccc;


/*==============================
	BUTTONS
==============================*/

$button-radius: 0;
$button-bg: $color-secondary;
$button-fg: $color-dark;
$button-primary-bg: $color-brand;
$button-primary-fg: $color-light;
